<template>
  <div id="app" :style="{ backgroundColor: stringToColor(color) }">
    <div :id="$mq === 'sm' ? 'top' : 'left'" :style="{ backgroundColor: stringToColor(color) }">
      <img :id="$mq === 'sm' ? 'map-sm': 'map'" v-if="data && !loading" :src="data" />
      <div :id="$mq==='sm' ? 'loading-sm' : 'loading'" v-if="loading"><h3>Fetching map.. please wait</h3></div>
      <div :id="$mq==='sm' ? 'error-sm' : 'error'" v-if="error">
        <h3>{{ error }}</h3>
      </div>
    </div>
    <div :id="$mq === 'sm' ? 'bottom' : 'right'" :style="{ backgroundColor: stringToColor(color) }">
      <div :id="$mq === 'sm' ? 'inputs-sm' : 'inputs'">
        <label for="settings"
          >Show Settings
          <br />
          <input
            type="checkbox"
            name="settings"
            class="input mid checkbox large"
            v-model="simpleView"
            :disabled="loading"
          />
        </label>
        <label for="stad"
          >Start Address
          <br />
          <input
            name="stad"
            :class="validAddress(startAddress) ? '' : 'input-error'"
            class="input wide"
            v-model="startAddress"
            :disabled="loading"
          />
        </label>
        <label for="endad"
          >End Address
          <br />
          <select
            name="endad"
            :class="validAddress(endAddress) ? '' : 'input-error'"
            class="input wide"
            v-model="endAddress"
            :disabled="loading"
          >
            <option v-for="add in endAddresses" :key="add.name">
              {{ add.name }}
            </option>
          </select>
        </label>
        <label for="color" v-if="simpleView"
          >Color (xxxxxx)
          <br />
          <input
            name="color"
            :class="validColor(color) ? '' : 'input-error'"
            class="input mid"
            v-model="color"
            :disabled="loading || randomize"
          />
        </label>
        <label for="randomize" v-if="simpleView"
          >Randomize color?
          <br />
          <input
            type="checkbox"
            name="randomize"
            class="input mid checkbox large"
            v-model="randomize"
            :disabled="loading"
          />
        </label>
        <!-- <label for="centerType"
          >Map Center Type
          <br />
          <select
            name="centerType"
            class="input mid"
            v-model="centerType"
            :disabled="loading"
          >
            <option
              v-for="type in centerTypes"
              :key="type.value"
              v-bind:value="type.value"
            >
              {{ type.name }}
            </option>
          </select>
        </label> -->
        <label for="zoom" v-if="simpleView"
          >Zoom (0 to 21, 0 is auto)
          <br />
          <input
            name="zoom"
            class="input mid"
            min="0"
            max="21"
            type="number"
            v-model="zoom"
            :disabled="loading"
          />
        </label>
        <label for="path" v-if="simpleView"
          >Path Width (0 to 20)
          <br />
          <input
            name="path"
            class="input mid"
            min="0"
            max="20"
            type="number"
            v-model="pathWidth"
            :disabled="loading"
          />
        </label>
        <label for="ibw" v-if="simpleView"
          >Inner Border Width (0 to 20)
          <br />
          <input
            name="ibw"
            class="input mid"
            min="0"
            max="40"
            type="number"
            v-model="innerBorderWidth"
            :disabled="loading"
          />
        </label>
        <label for="ibf" v-if="simpleView"
          >Inner Border Fill (0 to 100)
          <br />
          <input
            name="ibf"
            class="input mid"
            min="0"
            max="100"
            type="number"
            v-model="innerBorderFill"
            :disabled="loading"
          />
        </label>
        <label for="obw" v-if="simpleView"
          >Outer Border Width (0 to 20)
          <br />
          <input
            name="obw"
            class="input mid"
            min="0"
            max="40"
            type="number"
            v-model="outerBorderWidth"
            :disabled="loading"
          />
        </label>
        <label for="obf" v-if="simpleView"
          >Outer Border Fill (0 to 100)
          <br />
          <input
            name="obf"
            class="input mid"
            min="0"
            max="100"
            type="number"
            v-model="outerBorderFill"
            :disabled="loading"
          />
        </label>
        <label for="showTexture" v-if="simpleView"
          >Show Texture?
          <br />
          <input
            type="checkbox"
            name="showTexture"
            class="input mid checkbox large"
            v-model="showTexture"
            :disabled="loading"
          />
        </label>
        <label v-if="showTexture && simpleView" for="grain"
          >Grain Frequency (0 to 100)
          <br />
          <input
            name="grain"
            class="input mid"
            min="0"
            max="100"
            type="number"
            v-model="grainFrequency"
            :disabled="loading"
          />
        </label>
        <label for="editRender" v-if="simpleView"
          >Edit Image Render?
          <br />
          <input
            type="checkbox"
            name="editRender"
            class="input mid checkbox large"
            v-model="editRender"
            :disabled="loading"
          />
        </label>
        <label v-if="editRender && simpleView" for="contrast"
          >Contrast (-100 to 100)
          <br />
          <input
            name="contrast"
            class="input mid"
            min="-100"
            max="100"
            type="number"
            v-model="contrast"
            :disabled="loading"
          />
        </label>
        <label v-if="editRender && simpleView" for="bright"
          >Brightness (-100 to 100)
          <br />
          <input
            name="bright"
            class="input mid"
            min="-100"
            max="100"
            type="number"
            v-model="brightness"
            :disabled="loading"
          />
        </label>
      </div>
      <div :id="$mq === 'sm' ? 'buttons-sm' : 'buttons'">
        <button
          class="button mid"
          :disabled="!valid || loading"
          v-on:click="refreshImage()"
        >
          Update Map
        </button>
        <button class="button mid" :disabled="!valid || !data" v-on:click="exportMap()">
          Export Map
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  components: {},
  data() {
    return {
      startAddress: "Blue Copper Coffee Room",
      endAddress: "Blue Copper 2000",
      color: "fff000",
      zoom: 0,
      pathWidth: 10,
      contrast: 80,
      brightness: -100,
      centerType: 0,
      innerBorderWidth: 20,
      outerBorderWidth: 20,
      innerBorderFill: 100,
      outerBorderFill: 100,
      showTexture: true,
      editRender: false,
      grainFrequency: 25,
      data: NaN,
      loading: false,
      error: NaN,
      randomize: true,
      simpleView: false,
      prodUrl: "https://boiling-bastion-89710.herokuapp.com",
      devUrl: "http://localhost:8080",
      centerTypes: [
        { name: "Center on Path", value: 0 },
        { name: "Start Point", value: 1 },
        { name: "End Point", value: 2 },
      ],
      endAddresses: [
        { name: "Blue Copper 2000", value: "Blue Copper 2000" },
        { name: "Blue Copper Coffee Room", value: "Blue Copper Coffee Room" },
      ],
    };
  },
  mounted() {
    this.refreshImage();
  },
  computed: {
    valid() {
      return (
        this.validColor(this.color) &&
        this.validAddress(this.startAddress) &&
        this.validAddress(this.endAddress)
      );
    },
  },
  watch: {
    zoom() {
      if (this.zoom > 50) this.zoom = 50;
      if (this.zoom < 0) this.zoom = 0;
    },
    contrast() {
      if (this.contrast > 100) this.contrast = 100;
      if (this.contrast < -100) this.contrast = -100;
    },
    brightness() {
      if (this.brightness > 100) this.brightness = 100;
      if (this.brightness < -100) this.brightness = -100;
    },
    pathWidth() {
      if (this.pathWidth > 20) this.pathWidth = 20;
      if (this.pathWidth < 0) this.pathWidth = 0;
    },
    outerBorderWidth() {
      if (this.outerBorderWidth > 20) this.outerBorderWidth = 20;
      if (this.outerBorderWidth < 0) this.outerBorderWidth = 0;
    },
    innerBorderWidth() {
      if (this.innerBorderWidth > 20) this.innerBorderWidth = 20;
      if (this.innerBorderWidth < 0) this.innerBorderWidth = 0;
    },
    outerBorderFill() {
      if (this.outerBorderFill > 100) this.outerBorderFill = 100;
      if (this.outerBorderFill < 0) this.outerBorderFill = 0;
    },
    innerBorderFill() {
      if (this.innerBorderFill > 100) this.innerBorderFill = 100;
      if (this.innerBorderFill < 0) this.innerBorderFill = 0;
    },
    grainFrequency() {
      if (this.grainFrequency > 100) this.grainFrequency = 100;
      if (this.grainFrequency < 0) this.grainFrequency = 0;
    },
  },
  methods: {
    stringToColor(color) {
      if (color.length == 6) return "#" + color;
    },
    randomColor() {
      this.color = Math.floor(Math.random() * 16777215).toString(16);
      if (this.color.length == 5) this.color += "f";
    },
    validColor(color) {
      return color.length == 6; // should make this way more robust!
    },
    validAddress(address) {
      return address.length > 5;
    },
    exportMap() {
      var images = document.getElementsByTagName("img");
      var i = 0;
      setInterval(function () {
        if (images.length > i) {
          window.open(images[i].src, "_blank");
          i++;
        }
      }, 1000);
    },
    refreshImage() {
      this.loading = true;
      this.error = NaN;
      this.data = NaN;
      if (this.randomize) this.randomColor();
      axios
        .get(
          `${this.prodUrl}/api/v1/mapimage?export=${true}&fullRes=${false}&color=${
            this.color
          }&startAddress=${this.startAddress}&endAddress=${this.endAddress}&zoom=${
            this.zoom
          }&pathWidth=${this.pathWidth}&contrast=${this.contrast}&brightness=${
            this.brightness
          }&texture=${this.showTexture}&outerBorderWidth=${
            this.outerBorderWidth
          }&outerBorderFill=${this.outerBorderFill}&innerBorderWidth=${
            this.innerBorderWidth
          }&innerBorderFill=${this.innerBorderFill}&grainFrequency=${
            this.grainFrequency
          }&centerType=${this.centerType}`,
          { responseType: "blob" }
        )
        .then((res) => {
          console.log("Data retrieved. Why u lookin at the console?");
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "image/png" })
          );
          this.data = url;
          this.loading = false;
          console.log(url);
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.error =
            "Error fetching map. You likely used an incorrect value in one of the fields, entered invalid addresses or the mapping service went down. Also please note that this map generator is designed for addresses within a couple miles of eachother, so setting two points that are very far apart will cause it to error.";
        });
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

#app {
  font-family: "VT323", monospace;
  overflow: hidden;
}

#right {
  float: right;
  height: 100vh;
  overflow: hidden;
  width: 50%;
}

#left {
  float: left;
  height: 100%;
  overflow: hidden;
  width: 50%;
}

#top {
  float: right;
  height: 50vh;
  overflow: hidden;
  width: 100%;
}

#bottom {
  float: right;
  height: 50vh;
  overflow: hidden;
  width: 100%;
}

#map {
  width: 60%;
  height: 60%;
  margin: 25px;
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  border-radius: 5px;
}

#map-sm {
  margin-right: auto;
  margin-left: auto;
  height: 90%;
  margin: 25px;
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.25);
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  border-radius: 5px;
}

#inputs {
  width: 60%;
  height: 60%;
  padding: 25px;
  overflow-y: auto;
}

#inputs-sm {
  width: 100%;
  height: 60%;
  padding: 25px;
  overflow-y: auto;
}

#buttons {
  width: 60%;
  height: 20%;
  padding: 25px;
  overflow-y: hidden;
}


#buttons-sm {
  width: 100%;
  height: 20%;
  padding: 25px;
  overflow-y: hidden;
}

#loading {
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  margin-top: 30%;
  font-size: 200%;
}

#loading-sm {
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  margin-top: 30%;
}

#error {
  color: red;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  margin-top: 30%;
  font-size: 200%;
}

#error-sm {
  color: red;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  margin-top: 30%;
}

.input {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-right: 5%;
  background: rgba(255, 255, 255, 0.5);
  text-align: left;
  border: 0px solid rgba(0, 0, 0, 0.2);
  outline: none;
  font-size: 150%;
  border-radius: 5px;
}

.input:hover {
  background: rgba(255, 255, 255, 1);
  border: 0px solid rgba(0, 0, 0, 0.5);
}

.input:disabled {
  background: rgba(255, 255, 255, 0.1);
  border: 0px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.4);
}

.input-error {
  outline: 1px solid red;
  color: red;
}

.button {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  margin-right: 5%;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  border: 0px solid rgba(255, 255, 255, 0.7);
  cursor: pointer;
  color: white;
  font-size: 150%;
  outline: none;
  border-radius: 5px;
}

.button:hover {
  background: rgba(0, 0, 0, 0.9);
  border: 0px solid rgba(255, 255, 255, 1);
  color: white;
}

.button:disabled {
  color: rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 0px solid rgba(255, 255, 255, 0.1);
  cursor: not-allowed;
}

.mid {
  width: 40%;
}

.wide {
  width: 86.5%;
}

label {
  display: block;
}

.checkbox[type="checkbox"]:after {
  position: relative;
  display: block;
  border-radius: 3px;
  left: 2px;
  top: -11px;
  width: 7px;
  height: 7px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  content: "";
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox[type="checkbox"]:before {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  position: relative;
  display: block;
  width: 11px;
  height: 11px;
}

.checkbox[type="checkbox"]:disabled {
  background: white;
}

/* Large checkboxes */
.checkbox.large {
  height: 22px;
  width: 22px;
}

.checkbox.large[type="checkbox"]:before {
  width: 20px;
  height: 20px;
}
.checkbox.large[type="checkbox"]:after {
  top: -20px;
  width: 16px;
  height: 16px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  text-transform: uppercase;
  font-family: "VT323", monospace;
}

::-webkit-scrollbar {
  width: 2px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: black;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
